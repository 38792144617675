import { useContext, useState } from "react";

import { BottomBar, Menu, Seo } from "~components";
import { BaseContext } from "~context";

import { useRouter } from "next/router";
import { Layout } from "~components/common/Layout";
import { List, SubHeader } from "~components/events";
import { useEventsService } from "~features/events/hooks/useEventsService";

type QueryParams = {
  tourId: string;
};

const Events = () => {
  const { organization } = useContext(BaseContext);
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState<string>(undefined);
  const router = useRouter();
  const { tourId } = router?.query as QueryParams;

  const { events, featuredEvents, isLoading, error } = useEventsService({
    search,
    tourId,
    includeFeaturedEvents: true,
  });

  const toggleSearch = () => setShowSearch(!showSearch);

  return (
    <>
      <Seo title="Events overview" description="Events overview page for" />
      <Menu>
        <SubHeader
          showSearch={showSearch}
          setSearch={setSearch}
          toggleSearch={toggleSearch}
          hasBackground={!!organization?.branding?.background}
        />
      </Menu>
      <Layout layoutType="main">
        <List
          isLoading={isLoading}
          error={error}
          setSearch={setSearch}
          events={events}
          featuredEvents={featuredEvents}
        />
      </Layout>
      <BottomBar />
    </>
  );
};

export default Events;
