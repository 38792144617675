import React from "react";

import { Text, Box } from "flicket-ui";

import { Card, SlateRenderer } from "~components";
import { getImage } from "~lib";
import { formatDate } from "~lib/helpers";
import { ExtendedFile, SeasonsQuery } from "~graphql/sdk";
import { remainingEventsForSeason } from "~lib/helpers/remainingEventsForSeason";

type DateOrString = string | Date;

type TourOverviewTileProps = {
  id: string;
  name: string;
  events: SeasonsQuery["seasons"][number]["events"];
  startDate: DateOrString;
  endDate: DateOrString;
  thumbnail: ExtendedFile;
  description: string;
};

const DATE_FORMAT = "dd LLL yyyy";

export const TourOverviewTile = ({
  id,
  name,
  events,
  startDate,
  endDate,
  thumbnail,
  description,
}: TourOverviewTileProps) => {
  const eventDate =
    events.length > 1
      ? `${formatDate(startDate, DATE_FORMAT)} - ${formatDate(
          endDate,
          DATE_FORMAT
        )}`
      : formatDate(startDate, DATE_FORMAT);

  const remainingEventsCount = remainingEventsForSeason(events).length;

  return (
    <Box p="6/4" width={{ _: "100%", sm: "50%", md: "33.33%" }} key={id}>
      <Card
        minHeight="100%"
        linkProps={{
          to: `/tours/[tourId]`,
          linkAs: `/tours/${id}`,
        }}
        image={getImage(thumbnail)}
      >
        <Text
          fontSize={4}
          lineHeight="medium"
          fontWeight="heavy"
          variant="uppercase"
          letterSpacing={"-0.17px" as any}
          mb={1}
        >
          {`${name}`}
        </Text>

        <Text
          mb={1}
          fontSize={4}
          lineHeight="medium"
          letterSpacing={"-0.17px" as any}
        >
          {remainingEventsCount}
          {remainingEventsCount > 1 ? " events" : " event"}
        </Text>

        <Text fontSize={3} lineHeight="medium" letterSpacing={"-0.17px" as any}>
          {eventDate}
        </Text>

        {description && (
          <Box>
            <SlateRenderer
              value={description}
              mt={1}
              // Min height required for Safari as the below code
              // does not work for some reason. caniuse.com is showing all
              // properties available 🤷‍♂️
              minHeight={70}
              css={{
                "-webkit-line-clamp": "3",
                display: "-webkit-box",
                overflow: "hidden",
                "-webkit-box-orient": "vertical",
              }}
            />
          </Box>
        )}
      </Card>
    </Box>
  );
};
